@media (max-width: 1486px) {
    .header {
        &__col {
            &._btn-menu {
                display: block;
            }
            &._menu {
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                padding: 0 15px;
                width: auto;
                max-height: calc(100vh - 100%);
                overflow-y: auto;
                background: $white_color;
                box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.08);
                &._menu-has-open-uls {
                    height: 100vh;
                }
            }
        }
    }

    .menu {
        padding: 10px 0;
        [data-menu-item="burger"] {
            display: none;
        }
        .sublist-container-inner {
            @include flex(wrap, space-between, normal);
            > * {
                margin: 0 0 20px;
                width: calc(50% - 10px);
            }
        }
        .sublist__item {
            &-heading {
                padding: 10px 0;
                font-weight: 500;
                color: $grey_color;
            }
        }
        ul {
            > li {
                > a {
                    position: relative;
                    display: block;
                    padding: 10px 35px 10px 0;
                    font-size: 16px;
                    font-weight: 500;
                    color: $black_color_2;
                    text-decoration: none;
                    > .arrow {
                        position: absolute;
                        right: 0;
                        top: 2px;
                        @include flex();
                        width: 35px;
                        height: 35px;
                        cursor: pointer;
                        svg {
                            display: block;
                            color: inherit;
                        }
                    }
                }
                &.active {
                    > a {
                        color: $green_color;
                    }
                }
                > .sublist-container {
                    display: none;
                }
            }
            &._ul-mob-open {
                > li {
                    &:not(._li-mob-open) {
                        display: none;
                    }
                    &._li-mob-open {
                        > .sublist-container {
                            display: block;
                        }
                        > a {
                            padding: 10px 50px;
                            @include additionalFont;
                            font-size: 19px;
                            font-weight: 500;
                            color: $black_color_2;
                            text-align: center;
                            > .arrow {
                                right: auto;
                                left: -5px;
                                color: $green_color;
                                > svg {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .seo-block {
        ._left,
        ._right {
            max-width: 100%;
        }
    }

    .features-block {
        &__left {
            margin-bottom: 30px;
            max-width: 100%;
        }
        &__right {
            max-width: 100%;
        }
    }

    .big-products {
        &__side {
            display: none;
        }
        &__list {
            width: 100%;
        }
        &__item {
            &-image {
                max-width: 600px;
                min-height: 500px;
                &:before {
                    width: 360px;
                    height: 360px;
                }
            }
        }
    }

    .architect-quote {
        ._right {
            max-width: calc(100% - 380px);
        }
    }

    .footer {
        &__col {
            &._contacts,
            &._feedback {
                max-width: 33.3%;
            }
            &._callback {
                max-width: calc(33.3% - 40px);
            }
        }
    }

    .callback-rhombus {
        left: -10px;
        top: 0;
        width: 360px;
        height: 360px;
    }

    .product-intro {
        &-gallery {
            width: 60%;
        }
        &-data {
            width: 40%;
        }
    }

    .manager-contact {
        &__content {
            ._left {
                margin-bottom: 20px;
                max-width: 100%;
            }
            ._right {
                margin: 0;
            }
        }
    }
}

@media (max-width: 1199px) {
    .big-products {
        &__item {
            &-image {
                max-width: 500px;
                min-height: 400px;
                &:before {
                    width: 260px;
                    height: 260px;
                }
            }
        }
    }

    .portfolio-block {
        ._left {
            margin-bottom: 50px;
            max-width: 100%;
        }
        ._right {
            max-width: 100%;
        }
    }

    .callback-rhombus {
        width: 290px;
        height: 290px;
        &__text {
            max-width: 200px;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .product-intro {
        &-gallery {
            width: 50%;
        }
        &-data {
            width: 50%;
        }
    }
    
    .table-responsive-wrap {
        overflow-x: auto;
    }

    .sidebar-nav {
        >ul {
            >li {
                >a {
                    padding-left: 0;
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    .content-container {
        &__sidebar {
            width: 250px;
        }
        &__content {
            width: calc(100% - 280px);
        }
    }
}

@media (max-width: 991px) {
    .section-intro {
        &__nav {
            display: none;
        }
    }

    .advantages-block {
        &__item {
            margin-bottom: 25px;
            width: calc(50% - 30px);
        }
    }

    .big-products {
        &__item {
            &-image {
                max-width: 350px;
                min-height: 350px;
                &:before {
                    left: 30px;
                    width: 200px;
                    height: 200px;
                }
            }
        }
    }

    .footer {
        margin-top: 100px;
        &__col {
            &._contacts,
            &._feedback,
            &._callback {
                max-width: 100%;
            }
            &._contacts {
                margin-bottom: 50px;
            }
            &._callback {
                margin: 0 0 50px;
            }
        }
    }

    .callback-rhombus {
        position: static;
        margin: 0 auto;
    }

    .breadcrumb {
        font-size: 16px;
    }

    .pagetitle {
        .h1 {
            font-size: 32px;
        }
    }

    .product-filter {
        position: relative;
        top: 0;
        &__item {
            margin: 0 8px 16px;
            width: calc(50% - 16px);
        }
    }

    .product-list {
        margin-left: 0;
        margin-right: 0;
        &__item {
            margin: 0 0 24px;
            width: 100%;
        }
    }

    .product-intro {
        &-gallery,
        &-data {
            width: 100%;
        }
    }

    .product-navigation {
        order: 1;
        padding-top: 20px;
    }

    .product-params {
        padding-bottom: 20px;
    }

    .house-plans {
        &__title {
            ._value {
                margin-right: 20px;
            }
        }
    }

    .manager-contact {
        ._left,
        ._right {
            max-width: 100%;
        }
        ._right {
            margin: 30px 0 0;
        }
    }

    .content-container {
        &__sidebar {
            display: none;
        }
        &__content {
            margin-top: 0;
            width: 100%;
        }
    }

    .articles-block {
        &__list {
            &-container {
                .articles-block__item {
                    width: calc(33.3% - 30px);
                }
            }
        }
    }
}

@media (max-width: 767px) {
    body {
        font-size: 16px;
    }
    
    h1 {
        font-size: 1.5em;
    }

    .ubtn {
        padding: 0 8px;
        height: 40px;
        font-size: 16px;
    }

    .uform {
        &__fields {
            margin-bottom: 24px;
        }
        &__field {
            &:not(:last-child) {
                margin-bottom: 11px;
            }
            input, textarea {
                height: 45px;
                font-size: 16px;
            }
            textarea {
                height: 108px;
            }
        }
        &__submit {
            .ubtn {
                padding: 0 8px;
                width: 100%;
                font-size: 16px;
            }
        }
        &__agreement {
            margin-top: 17px;
            font-size: 12px;
            font-weight: 400;
        }
    }

    .slider-arrows {
        .slider-arrow {
            margin: 0 4px;
            svg {
                width: 32px;
                height: 32px;
            }
            &:not(.swiper-button-disabled) {
              svg {
                color: $blue_color;
              }
            }
          }
    }

    .header {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 17px 0;
        &__col {
            &._social,
            &._phones,
            &._cta {
                display: none;
            }
            &._menu {
                top: auto;
                bottom: 100%;
                box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.08);
            }
        }
        &__btn-menu {
            width: 18px;
            height: 18px;
        }
    }

    .menu {
        .sublist-container-inner {
            > * {
                width: 100%;
            }
        }
    }

    .logo {
        width: 110px;
        height: 21px;
        margin-bottom: 0;
    }

    .footer {
        margin-top: 50px;
        margin-bottom: 55px;
        &-body {
            padding: 40px 0;
        }
        &__col {
            &._contacts {
                margin-bottom: 30px;
            }
            &._callback {
                margin-bottom: 10px;
            }
        }
        &-bottom {
            padding: 15px 0;
        }
        &-links {
            font-size: 12px;
            > div {
                width: 100%;
                &:not(:last-child) {
                    margin: 0 0 7px;
                }
            }
            a {
                text-decoration: none;
            }
        }
    }

    .flogo {
        margin-bottom: 6px;
        width: 190px;
        height: 36px;
        svg {
            color: rgba(255,255,255,.5);;
        }
    }

    .femail {
        margin-bottom: 6px;
        &__item {
            a {
                font-size: 16px;
            }
        }
    }

    .fphones {
        margin-bottom: 7px;
        &__item {
            a {
                font-size: 25px;
                font-weight: 500;
            }
        }
    }

    .faddress {
        &__item {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
        }
    }

    .usection {
        margin: 40px 0;
        &.usection_intro {
            min-height: 0;
            height: auto !important;
        }
        &.usection_advantages {
            margin: 40px 0 -25px;
        }
        &.usection.usection_portfolio {
            margin: 40px 0;
            padding: 40px 0;
        }
        &.usection.usection_main-text {
            margin: 40px 0;
        }
        &.usection_pagetitle {
            margin-bottom: 0;
            + .usection_products-list {
                margin-top: 0;
            }
        }
        &.usection_productintro {
            margin-bottom: 40px;
        }
        &.usection_price-table {
            margin-bottom: 55px;
        }
    }

    .more-link {
        font-size: 16px;
        font-weight: 700;
        svg {
            width: 21px;
            height: 21px;
        }
    }

    .section-intro {
        padding: 20px 0;
        min-height: 412px;
        justify-content: center;
        &__content {
            margin: 0;
        }
        &__title {
            margin-bottom: 10px;
            ._value {
                margin-right: 15px;
                font-size: 25px;
            }
        }
        &__more {
            a {
                font-size: 16px;
                font-weight: 700;
                svg {
                    margin-left: 0;
                }
            }
        }
        &__slogan {
            margin-bottom: 18px;
            font-size: 19px;
        }
        &__cta {
            .ubtn {
                padding: 0 8px;
                font-size: 16px;
            }
        }
    }

    .seo-block {
        ._left {
            h1 {
                font-size: 25px;
                font-weight: 500;
                line-height: 1.3;
                margin: 0 0 15px;
            }
        }
        ._right {
            font-size: 16px;
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .features-text-slider {
        padding: 25px 15px;
        &__title {
            margin-bottom: 15px;
            font-size: 19px;
        }
        &__text {
            font-size: 16px;
            line-height: 1.5;
        }
        .slider-arrows {
            display: none;
        }
    }

    .features-image-slider {
        &__title {
            margin-top: 15px;
            font-size: 16px;
        }
        .slider-arrows {
            display: flex;
            margin-top: 25px;
        }
    }

    .advantages-block {
        &__item {
            margin-bottom: 30px;
            width: calc(50% - 15px);
            &-title {
                margin-bottom: 10px;
            }
            &-text {
                max-width: 100%;
                font-size: 16px;
            }
        }
    }

    .section-title {
        &.v1 {
            margin-bottom: 30px;
            font-size: 32px;
            font-weight: 300;
        }
    }

    .big-products {
        &__item {
            margin-bottom: 40px;
            flex-direction: column;
            &-image {
                margin: 0 0 30px;
                max-width: 100%;
                min-height: 200px;
                height: 200px;
                &:before {
                    bottom: 23px;
                    left: 32px;
                    width: 145px;
                    height: 145px;
                }
                img {
                    max-width: 87%;
                }
            }
            &-content {
                width: 100%;
            }
            &-title {
                margin-bottom: 25px;
                font-size: 25px;
            }
            &-options {
                margin-bottom: 10px;
                ._item {
                    ._image {
                        margin-bottom: 2px;
                    }
                }
            }
            &-square {
                font-size: 19px;
                margin: 0 0 20px;
            }
            &-price {
                font-size: 16px;
            }
            &-more {
                margin-top: 25px;
            }
        }
    }

    .architect-quote {
        ._left {
            max-width: 100%;
        }
        ._right {
            margin: 40px 0 0;
            max-width: 100%;
        }
        &__image {
            margin-left: auto;
            margin-right: auto;
            max-width: 250px;
        }
        &__name {
            margin-bottom: 15px;
            font-size: 25px;
        }
        &__position {
            margin-bottom: 15px;
            font-size: 19px;
        }
        &__text {
            font-size: 16px;
            line-height: 1.5;
        }
    }

    .portfolio-block {
        ._left {
            margin-bottom: 30px;
        }
        &__title {
            margin-bottom: 17px;
            font-size: 32px;
            font-weight: 300;
            line-height: 1.25;
        }
        &__text {
            margin-bottom: 23px;
            font-size: 16px;
        }
        &__more {
            .ubtn {
                width: 100%;
            }
        }
    }

    .articles-block {
        &__header {
            .more-link {
                display: none;
            }
        }
        &__list {
            .slider-arrows {
                bottom: calc(100% + 28px);
            }
        }
        &__item {
            &-image {
                margin-bottom: 12px;
            }
            &-postdate {
                margin-bottom: 11px;
                font-size: 12px;
            }
            &-title {
                a {
                    font-size: 16px;
                }
            }
        }
        &__more {
            display: block;
            margin-top: 20px;
        }
    }

    .main-text {
        &__content {
            font-size: 16px;
            h2 {
                font-size: 25px;
            }
            p {
                line-height: 1.5;
            }
        }
    }

    ._will-expand {
        .target-expand {
            max-height: 465px;
        }
        .text-expand {
            font-size: 16px;
        }
    }

    .breadcrumbs {
        margin-bottom: 15px;
    }

    .breadcrumb {
        font-size: 14px;
    }

    .pagetitle {
        .h1 {
            font-size: 25px;
        }
    }

    .product-filter {
        margin: 0 -15px 15px;
        padding: 25px 15px;
        border-radius: 0;
        &__item {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            &:last-child {
                margin-bottom: 0;
            }
            &-title {
                height: 40px;
                font-size: 16px;
            }
            &-list {
                top: calc(100% + 8px);
                .like-label {
                    ._value {
                        font-size: 16px;
                    }
                }
            }
        }
        &-labels {
            margin-bottom: 20px;
            ._item {
                position: relative;
                margin-top: 4px;
                margin-bottom: 4px;
                padding: 0 30px 0 10px;
                height: 40px;
                font-size: 16px;
                ._action {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    margin: -5px 0 0;
                }
                .uicon_close {
                    margin: 0;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    .product-list {
        &__item {
            padding: 35px 15px 20px;
            border-radius: 0;
            &-image {
                height: 170px;
            }
            &-title {
                margin-bottom: 20px;
                font-size: 25px;
            }
            &-options {
                margin: 0 -2px 10px;
                ._item {
                    min-width: 60px;
                    margin: 0 2px 15px;
                }
                ._image {
                    margin-bottom: 0;
                }
                ._text {
                    font-size: 12px;
                }
            }
            &-square {
                font-size: 19px;
                margin: 0 0 15px;
            }
            &-price {
                font-size: 16px;
            }
        }
    }

    .product-gallery-block {
        .slider-arrows {
            .slider-arrow {
                margin: 0 15px;
            }
        }
    }

    .product-title {
        margin-bottom: 25px;
        .h1 {
            font-size: 25px;
            font-weight: 600;
        }
    }

    .product-params {
        padding: 30px 15px;
    }

    .product-navigation {
        padding: 5px 15px 25px;
        font-size: 16px;
        ._item {
            &._next {
                .uicon {
                    margin-left: 12px;
                    width: 11px;
                    height: 11px;
                }
            }
        }
    }

    .product-options {
        margin: 0 -6px 10px;
        ._item {
            margin: 0 6px 16px;
            width: auto;
        }
        ._image {
            margin-bottom: 0;
        }
        ._text {
            font-size: 12px;
        }
    }

    .product-square {
        font-size: 19px;
        margin-bottom: 20px;
    }

    .product-price {
        margin-bottom: 25px;
        font-size: 16px;
    }

    .product-labels {
        &__item {
            margin-bottom: 15px;
            padding: 0 10px;
            height: 40px;
            border-width: 1px;
            font-size: 16px;
        }
    }

    .product-more {
        margin-top: 10px;
        width: 100%;
        .ubtn {
            width: 100%;
            font-size: 16px;
        }
    }

    .content-section {
        margin-bottom: 40px;
        h2 {
            margin-bottom: 16px;
            font-size: 32px;
            font-weight: 300;
            line-height: 1.25;
        }
        p {
            font-size: 16px;
            line-height: 1.5;
        }
        img {
            margin: 10px 0;
        }
        a {
            &._download {
                margin-top: 20px;
                font-size: 16px;
                &:after {
                    width: 19px;
                    height: 19px;
                }
            }
        }
    }

    .house-plans {
        &__item {
            margin-bottom: 20px;
        }
        &__title {
            margin-bottom: 20px;
            ._value {
                margin-right: auto;
                max-width: calc(100% - 100px);
                font-size: 19px;
            }
        }
    }

    .product-price-table-wrap {
        margin-bottom: 20px;
    }

    .product-price-table {
        &__tariff {
            &-name,
            &-price {
                font-size: 19px;
                font-weight: 500; 
            }
        }
        th, td {
            padding: 8px 12px;
        }
        th {
            padding-bottom: 15px;
            &.product-price-table__heading {
                font-size: 19px;
                font-weight: 500;
            }
        }
        td {
            font-size: 16px;
        }
        .product-price-table-offset {
            td {
              height: 20px;
            }
          }
    }

    .manager-contact {
        &__content {
            ._left {
                margin-bottom: 0;
            }
        }
        &__image {
            margin-left: auto;
            margin-right: auto;
            max-width: 250px;
        }
        &__title {
            font-size: 32px;
            margin-bottom: 24px;
        }
    }

    .umodal {
        &_decor {
            padding: 45px 15px;
            .uform__field {
                &:not(:last-child) {
                    margin-bottom: 11px;
                }
            }
        }
        &__title {
            font-size: 19px;
        }
        &__image {
            ._logo {
                margin-bottom: 20px;
                svg {
                    height: 50px;
                }
            }
        }
        &__product {
            ._image {
                height: 100px;
            }
            ._title {
                font-size: 25px;
                font-weight: 600;
            }
        }
        .uform {
            &__success {
                padding: 0;
                &-title {
                    font-size: 16px;
                }
                &-text {
                    font-size: 14px;
                }
            }
        }
    }

    .articles-block {
        &__list {
            &-container {
                .articles-block__item {
                    width: calc(50% - 30px);
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .portfolio-block {
        &__video {
            height: 160px;
        }
    }

    .articles-block {
        &__list {
            &-container {
                .articles-block__item {
                    width: calc(100% - 30px);
                }
            }
        }
    }
}