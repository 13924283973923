/* colors */

$black_color: #000;
$black_color_2: #2d2d28;
$white_color: #fff;
$green_color: #5daa32;
$blue_color: #035F94;
$grey_color: #828282;
$grey_color_2: #EDEFF3;
$grey_color_3: #BDBDBD;
$grey_color_4: #4D4D4B;
$grey_color_5: #656C7A;
$grey_color_6: #CACDD2;
$much_deep_blue: #27333E;

@mixin bg-prepare(
  $background-size: contain,
  $background-position: center,
  $background-repeat: no-repeat
) {
  background-size: $background-size;
  background-position: $background-position;
  background-repeat: $background-repeat;
}

@mixin flex(
  $wrap: wrap,
  $justify-content: center,
  $align-items: center,
  $flex-direction: row
) {
  display: flex;
  flex-direction: $flex-direction;
  flex-wrap: $wrap;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin additionalFont($font-family: 'Montserrat') {
  font-family: $font-family, sans-serif;
}

body {
  min-width: 320px;
  font-family: 'Jura', sans-serif;
  font-size: 18px;
  line-height: 1.2;
}

img {
  max-width: 100%;
  max-height: 100%;
}

input[type='text'] input[type='tel'] input[type='search'],
textarea {
  -webkit-appearance: none;
  appearance: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10 IE11 */
  body {
    &:before {
      content: 'Ваш браузер небезопасен. Используйте безопасные браузеры - https://browsehappy.com/';
      display: block;
      position: fixed;
      z-index: 5;
      width: 100%;
      top: 0;
      left: 0;
      padding: 15px;
      text-align: center;
      font-size: 20px;
      background-color: lightcoral;
      color: $black_color;
    }
  }
}

._long-text {
  &._inited {
    position: relative;
    max-height: 400px;
    overflow: hidden;
    &:not(&._active) {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(to top, $white-color, transparent);
      }
    }
  }
  &._active {
    max-height: 100%;
    ~ [class$='-toggler'] {
      display: none;
    }
  }
  &-toggler {
    color: $black_color;
    cursor: pointer;
    text-decoration: underline;
    margin: 15px 0 0;
    &:hover {
      text-decoration: none;
    }
  }
}

.ubtn {
  font-family: inherit;
  width: 100%;
  height: 50px;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  text-decoration: none !important;
  text-transform: lowercase;
  appearance: none;
  cursor: pointer;
  @include flex(wrap);
  &.loading {
    font-size: 0;
    @include bg-prepare(30%);
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.0' width='104px' height='13px' viewBox='0 0 128 16' xml:space='preserve'%3E%3Cpath fill='%23ffffff' d='M6.4,4.8A3.2,3.2,0,1,1,3.2,8,3.2,3.2,0,0,1,6.4,4.8Zm12.8,0A3.2,3.2,0,1,1,16,8,3.2,3.2,0,0,1,19.2,4.8ZM32,4.8A3.2,3.2,0,1,1,28.8,8,3.2,3.2,0,0,1,32,4.8Zm12.8,0A3.2,3.2,0,1,1,41.6,8,3.2,3.2,0,0,1,44.8,4.8Zm12.8,0A3.2,3.2,0,1,1,54.4,8,3.2,3.2,0,0,1,57.6,4.8Zm12.8,0A3.2,3.2,0,1,1,67.2,8,3.2,3.2,0,0,1,70.4,4.8Zm12.8,0A3.2,3.2,0,1,1,80,8,3.2,3.2,0,0,1,83.2,4.8ZM96,4.8A3.2,3.2,0,1,1,92.8,8,3.2,3.2,0,0,1,96,4.8Zm12.8,0A3.2,3.2,0,1,1,105.6,8,3.2,3.2,0,0,1,108.8,4.8Zm12.8,0A3.2,3.2,0,1,1,118.4,8,3.2,3.2,0,0,1,121.6,4.8Z'/%3E%3Cg%3E%3Cpath fill='%23ffffff' d='M-42.7,3.84A4.16,4.16,0,0,1-38.54,8a4.16,4.16,0,0,1-4.16,4.16A4.16,4.16,0,0,1-46.86,8,4.16,4.16,0,0,1-42.7,3.84Zm12.8-.64A4.8,4.8,0,0,1-25.1,8a4.8,4.8,0,0,1-4.8,4.8A4.8,4.8,0,0,1-34.7,8,4.8,4.8,0,0,1-29.9,3.2Zm12.8-.64A5.44,5.44,0,0,1-11.66,8a5.44,5.44,0,0,1-5.44,5.44A5.44,5.44,0,0,1-22.54,8,5.44,5.44,0,0,1-17.1,2.56Z'/%3E%3CanimateTransform attributeName='transform' type='translate' values='23 0;36 0;49 0;62 0;74.5 0;87.5 0;100 0;113 0;125.5 0;138.5 0;151.5 0;164.5 0;178 0' calcMode='discrete' dur='780ms' repeatCount='indefinite'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &_green-border {
    color: $green_color;
    border-color: $green_color;
    &:hover {
      background-color: $green_color;
      color: $white_color;
    }
  }
  &_white-border {
    color: $white_color;
    border-color: $white_color;
    &:hover {
      background-color: $white_color;
      color: $black_color;
    }
  }
  &_green {
    border-color: $green_color;
    color: $white_color;
    background-color: $green_color;
    &:hover {
      color: $white_color;
      background-color: darken($color: $green_color, $amount: 5);
      border-color: darken($color: $green_color, $amount: 5);
    }
  }
}

.uicon {
  display: inline-block;
  @include bg-prepare;
  &_minus {
    width: 15px;
    height: 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='2' viewBox='0 0 16 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H15' stroke='%23505050' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  &_plus {
    width: 15px;
    height: 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 1V15' stroke='%23505050' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 8H15' stroke='%23505050' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  &_close {
    width: 15px;
    height: 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 6.78743L14.536 0.250286C14.7029 0.0834284 14.9234 0 15.1429 0C15.6046 0 16 0.370285 16 0.855999C16 1.07657 15.9166 1.296 15.7497 1.464L9.21257 8L15.7486 14.536C15.9166 14.704 16 14.9234 16 15.1429C16 15.6309 15.6011 16 15.1429 16C14.9234 16 14.7029 15.9166 14.536 15.7497L8 9.21371L1.464 15.7497C1.29714 15.9166 1.07657 16 0.857143 16C0.398857 16 0 15.6309 0 15.1429C0 14.9234 0.0834283 14.704 0.251428 14.536L6.78743 8L0.250286 1.464C0.0834284 1.296 0 1.07657 0 0.855999C0 0.370285 0.395429 0 0.857143 0C1.07657 0 1.29714 0.0834284 1.464 0.250286L8 6.78743Z' fill='currentColor'/%3E%3C/svg%3E%0A");
  }
  &_arrow-left {
    width: 15px;
    height: 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.465126 6.00003L6.12113 0.343018L7.53516 1.75702L3.29313 6.00003L7.53516 10.243L6.12113 11.657L0.465126 6.00003Z' fill='%235DAA32'/%3E%3C/svg%3E%0A");
  }
  &_arrow-right {
    width: 15px;
    height: 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.07003 6.00003L1.41403 0.343018L6.18079e-08 1.75702L4.24203 6.00003L4.32743e-07 10.243L1.41403 11.657L7.07003 6.00003Z' fill='%235DAA32'/%3E%3C/svg%3E%0A");
  }
}

[data-upopup-container] {
  &:not([data-upopup-container-active]) {
    display: none;
  }
}