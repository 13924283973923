@import 'fonts';
@import 'vars';

@keyframes rotating {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}

p {
  margin: 20px 0;
  padding: 0;
}

h2 {
  margin: 20px 0;
  padding: 0;
  font-size: 25px;
  font-weight: 700;
}

.ucontainer {
  width: 100%;
  max-width: 1470px;
  padding: 0 15px;
  margin: 0 auto;
}

.header {
  padding: 20px 0 15px;
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: $white_color;
  box-shadow: 0 0 10px rgba($color: $black_color, $alpha: 0.3);
  &__row {
    @include flex(wrap, space-between);
  }
  &__col {
    &._logo {
      //margin-right: 15px;
    }
    &._btn-menu {
      display: none;
    }
    @media (min-width: 1487px) {
      &._menu {
        display: block;
      }
    }
  }
  svg {
    color: $black_color_2;
  }
  &__btn-menu {
    @include flex();
    width: 30px;
    height: 30px;
    color: $black_color_2;
    &:hover {
      color: $green_color;
    }
    &-i {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    &[data-upopup-btn-active] .header__btn-menu-i_open,
    &:not([data-upopup-btn-active]) .header__btn-menu-i_close {
      display: none;
    }
  }
}

.logo {
  width: 180px;
  height: 35px;
  margin-bottom: 5px;
}

.menu {
  > .sublist-container {
    display: none;
  }
  [data-menu-item=projects] {
    ._sublist-level-1 {
      &._bathrooms {
        display: none !important;
      }
    }
  }
  @media (min-width: 1487px) {
    .arrow {
      display: none;
    }
    ._item-level-1[data-menu-item-hidden-desktop] {
      display: none !important;
    }
    .rootlist {
      @include flex;
    }
    ._item-level-1 {
      margin: 0 15px;
    }
    ._subcont-level-1 {
      position: absolute;
      z-index: 20;
      opacity: 0;
      transform: translateY(-5px);
      visibility: hidden;
      transition: all 0.3s ease;
    }
  
    a {
      display: inline-block;
      font-size: 18px;
      font-weight: 600;
      text-decoration: none;
      color: $black_color_2;
      line-height: 1.4;
    }
  
    li {
      &:hover,
      &.active {
        > a {
          color: $green_color;
        }
      }
    }
    [data-menu-type='fullwidth'] {
      ._subcont-level-1 {
        top: 100%;
        left: 0;
        width: 100%;
        padding: 20px 0;
        box-shadow: 0 5px 5px rgba($color: $black_color, $alpha: 0.15);
      }
      ._sublist-level-1 {
        @include flex(wrap, flex-start, normal);
        width: 100%;
        max-width: 1470px;
        padding: 0 15px;
        margin: 0 auto;
        .sublist {
          li {
            margin: 30px 0;
          }
        }
      }
      ._item-level-2 {
        max-width: 240px;
        &:not(:first-child) {
          margin-left: 65px;
        }
        > a {
          color: $grey_color;
          &:hover {
            color: $green_color;
          }
        }
      }
    }
  
    [data-menu-item='burger'] {
      @include flex;
      > a {
        font-size: 0;
        display: block;
        width: 25px;
        height: 25px;
        cursor: pointer;
        @include bg-prepare;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0H6V3H0V0ZM0 7.5H24V10.5H0V7.5ZM18 15H24V18H18V15ZM8.25 0H24V3H8.25V0ZM0 15H15.75V18H0V15Z' fill='%232D2D28'/%3E%3C/svg%3E%0A");
      }
      ._subcont-level-1 {
        background-color: $white_color;
        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          bottom: 100%;
          left: 0;
          height: 25px;
          background-color: transparent;
        }
      }
      ._subcont-level-2 {
        display: block;
        position: static;
      }
      ._sublist-level-1 {
        padding-left: 240px;
      }
      &._active {
        ._subcont-level-1 {
          display: block;
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
        > a {
          background-size: 20px;
          background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.414 1.414L16 0L8.707 7.29303L1.414 0L0 1.414L7.293 8.70703L0 16L1.414 17.414L8.707 10.121L16 17.414L17.414 16L10.121 8.70703L17.414 1.414Z' fill='%232D2D28'/%3E%3C/svg%3E%0A");
        }
        ~ .parent {
          .sublist-container {
            display: none;
          }
        }
      }
    }
  
    [data-menu-item='projects'] {
      ._subcont-level-1 {
        display: block;
        padding: 50px 0 20px;
        background-color: $black_color_2;
        .sublist-container-inner {
          @include flex(wrap, flex-start, normal);
          width: 100%;
          max-width: 1470px;
          padding: 0 15px;
          margin: 0 auto;
        }
        a {
          color: $white_color;
        }
        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          bottom: 100%;
          left: 0;
          height: 25px;
          background-color: transparent;
        }
      }
      ._sublist-level-1 {
        display: block;
        margin: 0 0 0 60px; 
        padding: 0;
        width: auto;
        max-width: 100%;
        &:first-child {
          margin-left: 275px;
        }
      }
      .sublist__item {
        margin-bottom: 30px;
        &-heading {
          color: $grey_color;
          letter-spacing: 0.05em;
          text-transform: lowercase;
        }
        &:hover,
        &.active {
          > a {
            color: $green_color;
          }
        }
      }
      ._sublist-filter {
        .sublist__item {
          > a {
            text-transform: lowercase;
          }
        }
      }
    }
    .parent {
      &._item-level-1 {
        &:hover {
          ._subcont-level-1 {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
          }
        }
        &:not([data-menu-item]) {
          ._subcont-level-1 {
            background-color: $white_color;
            border-top: 3px solid $green_color;
            width: 300px;
            padding: 0 30px;
            top: calc(100% - 10px);
            box-shadow: 0 0 10px rgba($color: $black_color, $alpha: 0.15);
            .sublist__item {
              margin: 30px 0;
            }
            &:before {
              content: '';
              display: block;
              position: absolute;
              width: 100%;
              bottom: calc(100% + 3px);
              left: 0;
              height: 20px;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.social {
  @include flex;
  &__item {
    margin: 0 10px;
  }
}
.phones {
  &__item {
    a {
      font-weight: 700;
      text-decoration: none;
      color: $black_color_2;
      @include additionalFont;
    }
  }
}

.cta {
  .ubtn {
    min-width: 210px;
  }
}

.usection {
  margin: 60px 0;
  &.usection_intro {
    margin: 0;
    position: relative;
    height: 100vh;
    min-height: 780px;
    background-color: rgba($color: $black-color, $alpha: 0.2);
    .ucontainer {
      height: 100%;
    }
  }
  &.usection_advantages {
    margin: 120px 0;
  }
  &.usection_portfolio {
    background-color: $much_deep_blue;
    padding: 55px 0;
    margin: 100px 0 120px;
  }
  &.usection_main-text {
    margin: 120px 0 80px;
  }
  &.usection_pagetitle {
    margin: 0 0 50px;
    padding: 50px 0;
    @include bg-prepare(cover);
    background-image: url('/assets/images/page-title.jpg');
  }
  &.usection_productintro {
    margin: 0 0 100px;
    overflow-x: hidden;
  }
  &.usection_article-gallery {
    margin: 90px 0;
  }
  &.usection_price-table {
    margin-bottom: 150px;
  }
}

.section-title {
  &.v1 {
    font-size: 60px;
    font-weight: 200;
    margin: 0 0 70px;
    @include additionalFont;
    @include flex(wrap, space-between);
    h2 {
      font-size: inherit;
      font-weight: inherit;
      margin: 0;
    }
  }
}

.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.section-intro {
  padding: 30px 0 20px;
  height: 100%;
  @include flex(nowrap, flex-end, flex-start, column);
  &__content {
    width: 100%;
    margin-bottom: 30px;
  }
  &__title {
    @include flex(wrap, flex-start);
    margin: 0 0 20px;
    ._value {
      color: $white_color;
      font-size: 95px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0 50px 0 0;
      @include additionalFont;
    }
  }
  &__more {
    a {
      font-size: 24px;
      color: $green_color;
      @include flex;
      text-decoration: none;
      text-transform: lowercase;
      svg {
        margin-left: 10px;
      }
    }
  }
  &__slogan {
    font-size: 65px;
    font-weight: 200;
    color: $white_color;
    margin: 0 0 30px;
    @include additionalFont;
  }
  &__cta {
    .ubtn {
      width: max-content;
      padding: 0 20px;
    }
  }
  &__nav {
    width: 230px;
    height: 230px;
    margin: 0 auto;
    @include flex;
    color: $white_color;
    transition: all 0.3s ease;
    position: relative;
    &._hidden {
      opacity: 0;
    }
    &-text {
      animation: rotating 30s linear infinite;
      width: 100%;
      height: 100%;
    }
    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

._row {
  @include flex(wrap, space-between, normal);
  ._col {
    width: 100%;
    max-width: calc(50% - 20px);
  }
}

.seo-block {
  ._left {
    max-width: 520px;
    @include additionalFont;
    h1 {
      font-size: 42px;
      font-weight: 500;
      line-height: 1.4;
      margin: 0 0 30px;
    }
  }
  ._right {
    max-width: 880px;
    p {
      line-height: 1.6;
      font-weight: 500;
    }
  }
}

.more-link {
  display: inline-block;
  font-family: 'Jura', sans-serif;
  font-size: 24px;
  color: $green_color;
  a {
    @include flex(wrap, flex-start);
    display: inline-flex;
    color: currentColor;
    text-decoration: none;
    text-transform: lowercase;
  }
  span {
    margin-right: 10px;
  }
  svg {
    transition: all 0.3s ease;
  }
  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
}

.features-block {
  @include flex(wrap, space-between);
  &__left {
    width: 100%;
    max-width: 490px;
  }
  &__right {
    width: 100%;
    max-width: calc(100% - 490px - 70px);
  }
}

.slider-arrows {
  margin: 20px 0 0;
  @include flex;
  .slider-arrow {
    margin: 0 10px;
    cursor: pointer;
    svg {
      transition: all 0.3s ease;
      color: $grey_color-3;
    }
    &:hover {
      &:not(.swiper-button-disabled) {
        svg {
          color: $blue_color;
        }
      }
    }
    &.swiper-button-disabled {
      opacity: 0.6;
      cursor: initial;
    }
  }
}

.features-text-slider {
  background-color: $grey_color-2;
  border-radius: 5px;
  padding: 40px 40px 30px;
  &__title {
    font-size: 32px;
    font-weight: 500;
    @include additionalFont;
    margin-bottom: 25px;
    word-break: break-all;
  }
  &__text {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
  }

  .slider-arrows {
    justify-content: flex-end;
  }
}

.features-image-slider {
  &__item {
    width: 230px;
    max-width: 100%;
    margin: auto;
    &._has-video {
      [class*='__image'] {
        cursor: pointer;
        position: relative;
      }
    }
  }
  &__image {
    position: relative;
    width: 230px;
    height: 230px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: block;
      border: 2px solid transparent;
      border-radius: 50%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      &::selection {
        background-color: transparent;
      }
    }
  }
  &__title {
    margin: 20px 0 0;
    font-size: 18px;
    font-weight: 500;
    color: $blue_color;
    text-align: center;
    @include additionalFont;
    &::selection {
      background-color: transparent;
    }
  }
  .swiper-slide-thumb-active {
    [class*='__image'] {
      &:after {
        border-color: $blue_color;
      }
    }
    [class*='__title'] {
      color: $black_color;
    }
  }
  .slider-arrows {
    display: none;
  }
}

.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.umodal {
  position: relative;
  background-color: #fff;
  width: 100%;
  max-width: 700px;
  border-radius: 5px;
  padding: 30px 40px;
  margin: auto;
  &:not(.umodal_decor){
    button.mfp-close {
      filter: invert(1);
    }
  }
  &_decor {
    position: relative;
    max-width: 850px;
    background-color: $black_color_2;
    border-radius: 10px;
    padding: 40px 160px;
    box-shadow: 0 0 10px rgba($color: $black_color, $alpha: 0.3);
    .uform {
      max-width: 530px;
      margin: auto;
      &__field {
        &:not(:last-child) {
          margin: 0 0 30px;
        }
      }
      &__submit {
        .ubtn {
          width: 100%;
        }
      }
      &__success {
        color: $white_color;
      }
    }
  }
  &__image {
    ._logo {
      margin: 0 0 35px;
      svg {
        color: $grey_color_4;
      }
    }
  }
  &__title {
    font-size: 25px;
    font-weight: 400;
    @include additionalFont;
    color: $white_color;
    text-align: center;
    line-height: 1.5;
    margin: 0 0 30px;
  }
  &__product {
    padding-top: 30px;
    ._image {
      height: 200px;
      text-align: center;
    }
    ._title {
      font-size: 45px;
      font-weight: 700;
      color: $green_color;
      text-align: center;
      margin: 30px 0;
    }
  }
  video {
    max-width: 100%;
  }
}

.mfp-bg {
  background-color: $black_color_2;
  opacity: 0.7;
}

.mfp-img {
  background-color: $white_color;
}

button {
  &.mfp-close {
    font-size: 0;
    top: 5px;
    right: 5px;
    opacity: 1;
    @include bg-prepare(20px);
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.99967 8.48438L18.1016 0.381051C18.3084 0.174217 18.5818 0.0708008 18.8538 0.0708008C19.4262 0.0708008 19.9163 0.5298 19.9163 1.13188C19.9163 1.4053 19.8129 1.6773 19.6061 1.88555L11.5028 9.98747L19.6047 18.0894C19.8129 18.2976 19.9163 18.5696 19.9163 18.8416C19.9163 19.4465 19.4219 19.9041 18.8538 19.9041C18.5818 19.9041 18.3084 19.8007 18.1016 19.5939L9.99967 11.492L1.89776 19.5939C1.69092 19.8007 1.41751 19.9041 1.14551 19.9041C0.577425 19.9041 0.0830078 19.4465 0.0830078 18.8416C0.0830078 18.5696 0.186424 18.2976 0.394674 18.0894L8.49659 9.98747L0.393258 1.88555C0.186424 1.6773 0.0830078 1.4053 0.0830078 1.13188C0.0830078 0.5298 0.573174 0.0708008 1.14551 0.0708008C1.41751 0.0708008 1.69092 0.174217 1.89776 0.381051L9.99967 8.48438Z' fill='white'/%3E%3C/svg%3E%0A");
    &:hover {
      opacity: 0.8;
    }
  }
}

.mfp-fade {
  transition: all 0.3s ease;
}

.mfp-fade.mfp-bg {
  opacity: 0;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.7;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.advantages-block {
  @include flex(wrap, space-between, flex-start);
  &__item {
    width: calc(100% / 5 - 30px);
    &-title {
      font-size: 42px;
      font-weight: 500;
      margin: 0 0 15px;
      @include additionalFont;
    }
    &-text {
      line-height: 1.3;
      max-width: 60%;
    }
  }
}

.big-products {
  @include flex(wrap, flex-start, normal);
  &__side {
    width: 200px;
    margin-right: 30px;
  }
  &__list {
    width: calc(100% - 200px - 30px);
  }
  &__item {
    margin: 0 0 80px;
    @include flex(nowrap, flex-start, normal);
    &-image {
      width: 100%;
      max-width: 800px;
      min-height: 700px;
      margin-right: 20px;
      position: relative;
      @include flex;
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 50px;
        bottom: 110px;
        width: 460px;
        height: 460px;
        background-color: $grey_color-2;
        z-index: -1;
        transform: rotate(45deg);
        transition: all 0.3s ease;
      }
      a {
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    &-content {
      flex: 1 0;
    }
    &-title {
      font-size: 45px;
      font-weight: 600;
      color: $black_color_2;
      margin: 0 0 70px;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
    &-options {
      margin: 0 -5px 40px;
      @include flex(wrap, flex-start, normal);
      ._item {
        text-align: center;
        width: calc(100% / 4 - 10px);
        margin: 0 5px 15px;
        ._image {
          margin-bottom: 10px;
        }
        ._text {
          font-size: 12px;
          @include additionalFont;
        }
      }
    }
    &-square {
      font-size: 30px;
      margin: 0 0 50px;
      @include additionalFont;
      ._label {
        padding-left: 10px;
      }
    }
    &-price {
      font-size: 22px;
      @include additionalFont;
    }
    &-more {
      margin: 40px 0 0;
      .more-link {
        font-weight: 600;
      }
    }
    &._active {
      [class*='-image'] {
        &:before {
          transform: rotate(45deg) scale(1.2);
        }
      }
    }
  }
  &-sidebar {
    position: sticky;
    top: 115px;
    ._item {
      font-size: 24px;
      font-weight: 600;
      position: relative;
      cursor: pointer;
      &:not(:last-child) {
        margin: 0 0 45px;
      }
      &._active {
        color: $green_color;
        &:before {
          content: '';
          position: absolute;
          right: calc(100% + 20px);
          top: 50%;
          transform: translateY(-50%);
          width: 90px;
          height: 3px;
          background-color: $green_color;
        }
      }
    }
  }
}

.architect-quote {
  align-items: flex-end;
  ._left {
    max-width: 360px;
  }
  ._right {
    max-width: 995px;
    margin-left: 20px;
  }
  &__name {
    font-size: 28px;
    font-weight: 500;
    margin: 0 0 25px;
    @include additionalFont;
  }
  &__position {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 25px;
    @include additionalFont;
  }
  &__text {
    line-height: 1.7;
  }
}

.portfolio-block {
  ._left {
    max-width: 590px;
  }
  ._right {
    max-width: calc(100% - 610px);
  }
  &__title {
    font-size: 60px;
    font-weight: 200;
    color: $white_color;
    margin: 0 0 30px;
    line-height: 1;
    @include additionalFont;
  }
  &__text {
    font-size: 18px;
    color: $white_color;
    margin: 0 0 35px;
    line-height: 1.5;
    @include additionalFont;
  }
  &__more {
    .ubtn {
      width: max-content;
      padding: 0 20px;
    }
  }
  &__video {
    max-width: 605px;
    height: 340px;
  }
}

.articles-block {
  &__list {
    position: relative;
    .slider-arrows {
      margin: 0;
      position: absolute;
      right: 0;
      bottom: calc(100% + 15px);
      z-index: 20;
    }
    &-container {
      @include flex(wrap, flex-start, normal);
      margin: 0 -15px;
      .articles-block__item {
        margin: 0 15px 30px;
        width: calc(25% - 30px);
      }
    }
  }
  &__item {
    &-image {
      position: relative;
      margin: 0 0 35px;
      img {
        width: 100%;
      }
    }
    &-postdate {
      font-size: 14px;
      color: $grey_color;
      margin: 0 0 15px;
      @include additionalFont;
    }
    &-title {
      a {
        font-size: 18px;
        font-weight: 500;
        @include additionalFont;
        color: $black_color_2;
        text-decoration: none;
        &:hover {
          color: $blue_color;
        }
      }
    }
  }
  &__more {
    display: none;
  }
}

.main-text {
  &__content {
    h2 {
      font-size: 28px;
      font-weight: 500;
      margin: 0 0 20px;
      @include additionalFont;
    }
    p {
      line-height: 1.6;
    }
  }
}

._will-expand {
  .target-expand {
    max-height: 250px;
    overflow: hidden;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50px;
      background-image: linear-gradient(to bottom, transparent, $white_color);
    }
  }
  .text-expand {
    @include flex(nowrap, flex-start);
    color: $green_color;
    font-size: 18px;
    font-weight: 600;
    text-transform: lowercase;
    margin: 20px 0;
    cursor: pointer;
    &::selection {
      background-color: transparent;
    }
    ._collapse {
      display: none;
    }
    svg {
      margin-left: 10px;
    }
  }
  &._opened {
    .text-expand {
      svg {
        transform: rotate(180deg);
      }
    }
    ._collapse {
      display: block;
    }
    ._expand {
      display: none;
    }
    .target-expand {
      max-height: 100%;
      &::after {
        display: none;
      }
    }
  }
}

.footer {
  margin-top: 150px;
  &-body {
    background-color: $black_color_2;
    padding: 45px 0 70px;
    color: $grey_color_3;
  }
  &-bottom {
    padding: 35px 0;
  }
  &__row {
    @include flex(wrap, flex-start, normal);
  }
  &__col {
    width: 100%;
    &._contacts {
      max-width: 440px;
      @include flex(wrap, flex-start, flex-start, column);
    }
    &._callback {
      max-width: 400px;
      position: relative;
      margin: 0 20px;
    }
    &._feedback {
      max-width: 530px;
    }
  }
}

.flogo {
  width: 260px;
  height: 50px;
  margin: 0 0 20px;
  svg {
    color: $grey_color_3;
  }
}

.femail {
  flex: 1 0;
  &__item {
    margin: 10px 0;
    a {
      color: $white_color;
      font-size: 24px;
      font-weight: 500;
      text-decoration: none;
      &:hover {
        color: $green_color;
      }
    }
  }
}

.fphones {
  margin: 0 0 30px;
  &__item {
    margin: 10px 0;
    a {
      font-size: 32px;
      font-weight: 600;
      color: $white_color;
      @include additionalFont;
      text-decoration: none;
      &:hover {
        color: $green_color;
      }
    }
  }
}
.faddress {
  &__item {
    font-size: 20px;
    color: rgba($color: $white_color, $alpha: 0.5);
  }
}

.callback-rhombus {
  position: absolute;
  left: -270px;
  top: -160px;
  width: 660px;
  height: 660px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  @include flex;
  @include bg-prepare(cover);
  background-image: url(../images/house.jpg);
  &__text {
    max-width: 300px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6;
    color: $white_color;
    @include additionalFont;
  }
}

.uform {
  &__fields {
    margin: 0 0 30px;
  }
  &__field {
    &:not(:last-child) {
      margin: 0 0 25px;
    }
    input,
    textarea {
      font-family: inherit;
      width: 100%;
      height: 50px;
      font-size: 18px;
      font-weight: 500;
      color: $white_color;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid $grey_color_4;
      border-radius: 5px;
      padding: 0 20px;
      outline: none;
      &:focus {
        &:not(.error) {
          border-color: $green_color;
        }
      }
      &.error {
        border-color: lightcoral;
        color: lightcoral;
        &::placeholder {
          color: inherit;
        }
      }
    }
    textarea {
      height: 160px;
      padding: 15px 20px;
      resize: none;
      line-height: 1.3;
      background-color: $grey_color_4;
    }
  }
  &__submit {
    .ubtn {
      width: max-content;
      padding: 0 20px;
    }
  }

  &__agreement {
    margin: 40px 0 10px;
    font-size: 15px;
    font-weight: 700;
    color: $grey_color;
    a {
      color: inherit;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__success {
    display: none;
    text-align: center;
    padding: 50px;
    &-icon {
      margin: 0 0 10px;
      svg {
        color: $green_color;
      }
    }
    &-title {
      font-size: 24px;
      margin: 0 0 30px;
    }
    &-text {
      margin: 0 15px;
    }
  }
  &.ajax_form {
    .error {
      color: lightcoral;
    }
  }
  &_manager {
    [class*='__field'] {
      input {
        border-color: $grey_color_6;
        color: $black_color;
      }
    }
  }
}

.footer-links {
  color: $grey_color;
  font-size: 14px;
  font-weight: 400;
  @include additionalFont;
  @include flex(wrap, flex-start);
  a {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
  > div {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.breadcrumbs {
  margin: 0 0 30px;
}

.breadcrumb {
  margin: 0;
  padding: 0;
  list-style: none;
  color: $white_color;
  @include flex(wrap, flex-start);
  @include additionalFont;
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &-item {
    &.active {
      color: $grey_color_2;
    }
  }
  ._separator {
    padding: 0 10px;
  }
}

.pagetitle {
  .h1 {
    margin: 0;
    font-weight: 500;
    font-size: 55px;
    color: $white_color;
    @include additionalFont;
  }
}

.content {
  min-height: 500px;
  line-height: 1.4;
  p img {
    width: auto;
    height: auto;
  }
}

.product-filter {
  position: sticky;
  top: 100px;
  z-index: 15;
  background-color: $black_color_2;
  padding: 25px;
  border-radius: 5px;
  margin: 0 0 30px;
  &__list {
    @include flex(wrap, space-between);
  }
  &__item {
    width: calc(100% / 4 - 20px);
    margin: 0 5px;
    position: relative;
    &._active {
      [class*='-list'] {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
    &-title {
      height: 50px;
      background-color: $grey_color_4;
      color: $white_color;
      border-radius: 5px;
      padding: 0 20px;
      text-transform: lowercase;
      cursor: pointer;
      @include flex(nowrap, space-between);
      svg {
        flex-shrink: 0;
        color: inherit;
      }
    }
    &-list {
      position: absolute;
      z-index: 10;
      width: 100%;
      left: 0;
      top: calc(100% + 15px);
      border-top: 3px solid $green_color;
      background-color: $white_color;
      padding: 10px 15px 20px;
      box-shadow: 0px 4px 12px rgba($color: $black-color, $alpha: 0.05);
      opacity: 0;
      visibility: hidden;
      transform: translateY(-10px);
      transition: all 0.3s ease;
      .like-label {
        cursor: pointer;
        @include flex(nowrap, flex-start);
        ._icon {
          flex-shrink: 0;
          width: 25px;
          height: 25px;
          border: 1px solid $grey_color_5;
          border-radius: 2px;
          margin-right: 10px;
          transition: all 0.3s ease;
          @include bg-prepare(15px);
        }
        ._value {
          font-size: 18px;
          font-weight: 600;
          text-transform: lowercase;
        }
      }
      label {
        display: inline-block;
        margin: 10px 0;
        width: 100%;
      }
      input {
        display: none;
        &:checked {
          ~ .like-label {
            ._icon {
              background-color: $green_color;
              background-image: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7.29272L5.8272 12L17 1' stroke='white'/%3E%3C/svg%3E%0A");
              border-color: $green_color;
            }
          }
        }
      }
    }
  }
}

.product-list {
  margin: 0 -15px;
  @include flex(wrap, flex-start, normal);
  &__item {
    width: calc(100% / 2 - 30px);
    margin: 0 15px 30px;
    background-color: $grey_color_2;
    border-radius: 10px;
    padding: 40px;
    transition: all 0.3s ease;
    &:hover {
      background-color: darken($color: $grey_color_2, $amount: 3);
    }
    &-image {
      height: 410px;
      margin: 0 0 30px;
      position: relative;
      @include flex;
      a {
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    &-title {
      font-size: 42px;
      font-weight: 600;
      color: $black_color_2;
      margin: 0 0 60px;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
    &-options {
      margin: 0 -10px 40px;
      @include flex(wrap, flex-start);
      @include additionalFont;
      ._item {
        min-width: 75px;
        text-align: center;
        margin: 0 10px 15px;
      }
      ._image {
        margin-bottom: 5px;
      }
      ._text {
        font-size: 14px;
      }
    }
    &-square {
      font-size: 34px;
      margin: 0 0 40px;
      @include additionalFont;
      ._label {
        padding-left: 10px;
      }
    }
    &-price {
      font-size: 26px;
      @include additionalFont;
    }
  }
  &__empty {
    margin: 0 15px 30px;
  }
}

.product-filter-labels {
  margin: 0 -10px 30px;
  @include flex(wrap, flex-start);
  ._item {
    min-width: 200px;
    border: 1px solid $black_color_2;
    border-radius: 5px;
    height: 50px;
    padding: 0 25px;
    font-size: 18px;
    font-weight: 600;
    margin: 10px;
    text-transform: lowercase;
    @include flex(wrap, space-between);
    ._action {
      margin-left: 20px;
      cursor: pointer;
      @include flex;
      .uicon {
        pointer-events: none;
      }
    }
  }
}

.product-intro {
  &-row {
    min-height: 80vh;
    @include flex(wrap, flex-start, normal);
  }
  &-gallery {
    position: relative;
    width: calc((100% / 3) * 2);
  }
  &-data {
    position: relative;
    width: calc(100% / 3);
    background-color: $black_color_2;
    color: $white_color;
    @include flex(wrap, flex-start, flex-start, column);
  }
}

.product-gallery-block {
  height: 100%;
  .slider-arrows {
    position: absolute;
    width: 100%;
    top: 50%;
    z-index: 10;
    margin: 0;
    transform: translateY(-50%);
    justify-content: space-between;
  }
  .swiper {
    height: 100%;
  }
  .swiper-slide {
    @include flex;
  }
  .slider-arrows {
    .slider-arrow {
      margin: 0 40px;
      &:not(.swiper-button-disabled) {
        svg {
          color: $green_color;
        }
      }
      &.swiper-button-disabled {
        opacity: 0;
      }
      &:hover {
        &:not(.swiper-button-disabled) {
          svg {
            color: $black_color_2;
          }
        }
      }
    }
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.product {
  &-navigation {
    width: 100%;
    padding: 40px 20px 20px;
    color: $green_color;
    font-size: 24px;
    font-weight: 700;
    ._nav-list {
      @include flex(wrap, space-between);
    }
    ._item {
      color: inherit;
      text-decoration: none;
      max-width: max-content;
      flex-grow: 1;
      @include flex;
      &:hover {
        text-decoration: underline;
      }
      &._prev {
        margin-right: auto;
        .uicon {
          margin-right: 15px;
        }
      }
      &._next {
        margin-left: auto;
        .uicon {
          margin-left: 15px;
        }
      }
    }
  }
  &-params {
    padding: 20px 40px 60px;
    width: 100%;
    flex-grow: 1;
    @include flex(wrap, flex-start, flex-start, column);
  }
  &-title {
    margin: 0 0 55px;
    .h1 {
      font-size: 45px;
      font-weight: 700;
      margin: 0;
    }
  }
  &-options {
    margin: 0 -5px 30px;
    max-width: calc(95px * 4);
    @include flex(wrap, flex-start, normal);
    @include additionalFont;
    ._item {
      text-align: center;
      width: 85px;
      margin: 0 5px 25px;
    }
    ._image {
      margin-bottom: 10px;
    }
    ._text {
      font-size: 14px;
    }
  }
  &-square {
    font-size: 30px;
    margin: 0 0 40px;
    @include additionalFont;
    ._label {
      padding-left: 10px;
    }
  }
  &-price {
    font-size: 22px;
    margin: 0 0 40px;
    @include additionalFont;
  }
  &-labels {
    @include flex(wrap, flex-start, flex-start, column);
    &__item {
      width: max-content;
      border: 2px solid $white_color;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 600;
      height: 50px;
      padding: 0 25px;
      text-transform: lowercase;
      margin: 0 0 30px;
      @include flex;
    }
  }
  &-more {
    margin: 20px 0 0;
    flex-grow: 1;
    @include flex(wrap, flex-end, flex-start, column);
    .ubtn {
      width: max-content;
      padding: 0 20px;
    }
  }
}

.manager-contact {
  ._left {
    max-width: 360px;
  }
  ._right {
    max-width: calc(100% - 380px);
    margin-left: 20px;
  }
  &__title {
    font-size: 42px;
    font-weight: 500;
    margin: 0 0 30px;
    @include additionalFont;
  }
  &__content {
    ._left {
      max-width: 345px;
      font-weight: 500;
      @include additionalFont;
    }
    ._right {
      max-width: 570px;
      margin-left: 20px;
    }
  }
  &__name {
    font-size: 25px;
    margin: 0 0 20px;
  }
  &__position {
    font-size: 17px;
  }
  &__form {
    padding: 25px 20px;
    border-radius: 5px;
    background-color: $grey_color_2;
  }

  ._like-label-phone {
    font-size: 24px;
    font-weight: 600;
    color: $green_color;
  }
}

.content-section {
  margin: 0 0 80px;
  h2 {
    font-size: 64px;
    font-weight: 200;
    @include additionalFont;
    margin: 20px 0 40px;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
  }
  img {
    margin: 20px 0;
  }
  a {
    &._download {
      font-size: 24px;
      font-weight: 600;
      color: $green_color;
      text-decoration: none;
      margin: 30px 0 15px;
      @include flex(nowrap, flex-start);
      &:after {
        content: '';
        display: block;
        width: 25px;
        height: 25px;
        margin-left: 10px;
        flex-shrink: 0;
        transition: all .3s ease;
        @include bg-prepare;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 21.3333V24H24V21.3333H0ZM17.3333 13.3333H13.3333V0H10.6667V13.3333H6.66667L12 18.6667L17.3333 13.3333Z' fill='%235DAA32'/%3E%3C/svg%3E%0A");
      }
      &:hover {
        &:after {
          transform: translateY(5px);
        }
      }
    }
  }
}

.house-plans {
  &__item {
    margin:  0 0 50px;
  }
  &__title {
    @include flex(wrap, flex-start, normal);
    margin: 0 0 50px;
    ._value {
      margin-right: 40px;
      font-size: 36px;
      font-weight: 400;
      color: $blue_color;
      @include additionalFont;
    }

    .slider-arrows {
      margin: 0;
      .slider-arrow {
        &:not(.swiper-button-disabled) {
          svg {
            color: $blue_color;
          }
        }
      }
    }
  }

  .swiper-slide {
    opacity: 0.5;
    transition: all .3s ease;
    &.swiper-slide-active {
      opacity: 1;
    }
  }
}

.product-price-table-wrap {
  margin: 0 0 60px;
}

.product-price-table {
  margin: 0;
  padding: 0;
  width: 100%;
  border: 0;
  border-collapse: collapse;
  @include additionalFont;
  tr {
    &:nth-child(even) {
      th, td {
        background: $grey_color_2;
      }
    }
  }
  th, td {
    padding: 17px 27px;
    border: 0;
    vertical-align: middle;
    text-align: center;
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      text-align: left;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &:first-child:not(:last-child) {
      width: 44%;
    }
  }
  th {
    padding-top: 0;
    padding-bottom: 45px;
    &.product-price-table__heading {
      font-size: 30px;
      font-weight: 600;
      color: $black_color_2;
    }
  }
  td {
    font-size: 18px;
    font-weight: 500;
    color: $blue_color;
  }
  &__tariff {
    &-name {
      font-size: 60px;
      font-weight: 500;
      color: $green_color;
    }
    &-price {
      white-space: nowrap;
      font-size: 24px;
      font-weight: 600;
      color: $black_color_2;
    }
  }
  &__plus {
    display: block;
    margin: auto;
    width: 24px;
    height: 24px;
    background: url(../img/plus.svg) 0 0 no-repeat;
  }
  .product-price-table-offset {
    td {
      height: 50px;
      background: transparent !important;
    }
  }
  .product-price-table-additional {
    background: #EFEFEF;
    border-radius: 5px;
    tr {
      &:nth-child(even) {
        th, td {
          background: #F9F9F9;
        }
      }
    }
    th {
      padding-top: 25px;
      padding-bottom: 25px;
      &.product-price-table__heading {
        color: $grey_color_3;
      }
    }
    td {
      color: $black_color_2;
    }
    .product-price-table {
      &__plus {
        background-image: url(../img/plus-gray.svg);
      }
    }
  }
}

.content-container {
  @include flex(wrap, space-between, stretch);
  &__sidebar {
    width: 385px;
  }
  &__content {
    margin-top: -10px;
    width: calc(100% - 415px);
    font-size: 16px;
    h1 {
      &:first-child {
        margin-top: 0;
      }
    }
    .usection_article-gallery {
      .ucontainer {
        padding: 0;
      }
    }
  }
}

.sidebar-nav {
  > ul {
    > li {
      margin-bottom: 30px;
      > a {
        position: relative;
        display: block;
        padding-left: 110px;
        font-weight: 600;
        color: $black_color_2;
        text-decoration: none;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -2px;
          display: block;
          width: 93px;
          height: 3px;
        }
      }
      &:hover,
      &.active {
        > a {
          color: $green_color;
        }
      }
      &.active {
        > a {
          &:before {
            background-color: $green_color;
          }
        }
      }
    }
  }
}

.uol {
  margin: 65px 0;
  padding: 0;
  max-width: 610px;
  counter-reset: uol;
  > li {
    margin-bottom: 45px;
    position: relative;
    padding-left: 53px;
    &:last-child {
      margin-bottom: 0;
    }
    &::before {
      counter-increment: uol;
      content: counter(uol);
      position: absolute;
      left: 0;
      top: 2px;
      @include flex();
      width: 34px;
      height: 34px;
      border: 1px solid $green_color;
      border-radius: 50%;
      font-size: 16px;
      color: $green_color;
    }
  }
}

.article-gallery {
  &__title {
    @include flex(wrap, flex-start, center);
    margin: 0 0 50px;
    ._value {
      font-size: 25px;
      font-weight: 700;
      color: $black_color_2;
    }
    .slider-arrows {
      margin: 0 0 0 40px;
      .slider-arrow {
        &:not(.swiper-button-disabled) {
          svg {
            color: $blue_color;
          }
        }
      }
    }
  }
  .image-link {
    &, img {
      display: block;
    }
  }
}

@import 'media';