/* jura-500 - latin_cyrillic */
@font-face {
  font-family: 'Jura';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Jura/jura-v24-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Jura/jura-v24-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Jura/jura-v24-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Jura/jura-v24-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Jura/jura-v24-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Jura/jura-v24-latin_cyrillic-500.svg#Jura') format('svg'); /* Legacy iOS */
}
/* jura-regular - latin_cyrillic */
@font-face {
  font-family: 'Jura';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Jura/jura-v24-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Jura/jura-v24-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Jura/jura-v24-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Jura/jura-v24-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Jura/jura-v24-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Jura/jura-v24-latin_cyrillic-regular.svg#Jura') format('svg'); /* Legacy iOS */
}
/* jura-700 - latin_cyrillic */
@font-face {
  font-family: 'Jura';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Jura/jura-v24-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Jura/jura-v24-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Jura/jura-v24-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Jura/jura-v24-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Jura/jura-v24-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Jura/jura-v24-latin_cyrillic-700.svg#Jura') format('svg'); /* Legacy iOS */
}
/* jura-600 - latin_cyrillic */
@font-face {
  font-family: 'Jura';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Jura/jura-v24-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Jura/jura-v24-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Jura/jura-v24-latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Jura/jura-v24-latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Jura/jura-v24-latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Jura/jura-v24-latin_cyrillic-600.svg#Jura') format('svg'); /* Legacy iOS */
}

/* montserrat-200 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-200.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-300 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Montserrat/montserrat-v25-latin_cyrillic-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}